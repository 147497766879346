<template>
  <div>
    <b-modal ref="wDialog" :title="title" ok-only>
      <span id="wDialogMsg">Works in progress</span>
    </b-modal>
  </div>
</template>
<script>
export default {
  components: {
  },
  props: {
      title: {
          type: String,
            default: 'Works in Progress'
      }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  methods: {
    show() {
      this.$refs.wDialog.show()
    },
  }
}    
</script>
<style scoped>
</style>